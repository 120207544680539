import {
  Field,
  Input as UIInput,
  type InputProps as UIInputProps,
  Label,
} from "@headlessui/react";
import type { ValidationError } from "@tanstack/react-form";
import classNames from "classnames";

interface InputFieldProps extends UIInputProps {
  label?: string;
  suffix?: string;
  error?: ValidationError;
}

export default function InputField({
  label,
  suffix,
  error,
  ...props
}: InputFieldProps) {
  return (
    <Field className={classNames("flex-col flex")}>
      {label && (
        <Label className="font-sans font-semibold text-lg pb-2">{label}</Label>
      )}
      <div
        className={classNames(
          "py-3 border-b border-gray-600 min-w-60 focus-within:border-gray-900 flex justify-between",
          {
            "border-red focus-within:border-red": error,
          },
        )}
      >
        <UIInput
          className="text-xl bg-gray-100 flex flex-1 font-mono font-bold placeholder:text-gray-600 data-[focus]:outline-none"
          {...props}
        />
        {suffix && (
          <span className="ml-auto pl-2 text-xl text-gray-900 font-bold font-mono">
            {suffix}
          </span>
        )}
      </div>
      {error && <p className="text-red text-base pt-1 font-sans">{error}</p>}
    </Field>
  );
}
