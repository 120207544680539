"use client";

import Button from "@/components/Button";
import ConnectWalletButton from "@/components/ConnectWalletButton";
import { URL_PATHS } from "@/constants/navigation";
import { useAuthorizationContext } from "@/context/authorizationContext";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { usePathname } from "next/navigation";

export default function TopBar() {
  const { isLoggedIn, showAccessDeniedModal, isAuthorizationReady } =
    useAuthorizationContext();

  const router = useRouter();

  const pathname = usePathname();
  return (
    <nav className="w-full bg-gray-100 fixed px-10 h-28 flex flex-row justify-between items-center border-b border-gray-400 gap-6 z-30">
      <Link href="/" className="font-mono text-2xl font-semibold">
        karpatkey
      </Link>
      <div className="gap-10 flex">
        {pathname !== URL_PATHS.OPERATOR && (
          <div className="gap-4 flex">
            <Button
              disabled={!isAuthorizationReady}
              variant="primary"
              onClick={() => {
                isLoggedIn
                  ? router.push(URL_PATHS.SUBSCRIBE)
                  : showAccessDeniedModal();
              }}
            >
              Subscribe
            </Button>
            <Button
              disabled={!isAuthorizationReady}
              variant="secondary"
              onClick={() => {
                isLoggedIn
                  ? router.push(URL_PATHS.REDEEM)
                  : showAccessDeniedModal();
              }}
            >
              Redeem
            </Button>
          </div>
        )}
        <ConnectWalletButton />
      </div>
    </nav>
  );
}
