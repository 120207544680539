"use client";

import classNames from "classnames";

import {
  Button as UIButton,
  type ButtonProps as UIButtonProps,
} from "@headlessui/react";

interface ButtonProps extends UIButtonProps {
  variant: "primary" | "secondary";
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

const variantStyles = {
  primary:
    "bg-gray-900 text-gray-100 data-[disabled]:bg-gray-700 data-[disabled]:text-gray-600 data-[disabled]:data-[hover]:bg-gray-900 data-[disabled]:data-[hover]:text-gray-600 data-[hover]:bg-gray-800 data-[hover]:text-gray-200",
  secondary:
    "bg-gray-100 text-gray-900 data-[disabled]:bg-gray-200 data-[disabled]:text-gray-800 data-[disabled]:data-[hover]:bg-gray-200 data-[disabled]:data-[hover]:text-gray-800 data-[hover]:bg-gray-200 data-[hover]:text-gray-900",
};

export default function Button({
  variant,
  onClick,
  disabled = false,
  className,
  icon,
  children,
  ...props
}: ButtonProps) {
  return (
    <UIButton
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        "h-12 min-w-36 rounded-[80px] border border-gray-900  font-mono text-base font-bold gap-3 flex-row flex items-center justify-center data-[disabled]:opacity-90 px-5",
        variantStyles[variant],
        className,
      )}
      {...props}
    >
      <span className="flex-1 justify-center">{children}</span>
      {icon}
    </UIButton>
  );
}
