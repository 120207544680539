import type { Address } from "viem";
import * as viemChains from "viem/chains";
export function shortenAddress(address: Address): string {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

export const openTransactionBlockExplorer = (
  hash: string,
  chainName: string,
): void => {
  const chain = viemChains[chainName as keyof typeof viemChains];
  const blockExplorer = chain.blockExplorers?.default.url;
  if (blockExplorer) {
    window.open(`${blockExplorer}/tx/${hash}`, "_blank");
  }
};

export const openAddressBlockExplorer = (
  address: string,
  chainName: string,
): void => {
  const chain = viemChains[chainName as keyof typeof viemChains];
  const blockExplorer = chain.blockExplorers?.default.url;
  if (blockExplorer) {
    window.open(`${blockExplorer}/address/${address}`, "_blank");
  }
};
